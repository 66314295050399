<template>
  <div class='relative'>
    <button
      @click='toggleFundsFilters'
      class='uppercase text-sm text-left px-2 py-2 flex flex-row items-center gap-x-1 hover:bg-gray-50 border border-solid rounded border-transparent hover:border-gray-200 hover:shadow-lg'>
      <span class='inline-block w-48 truncate'>{{ filterFundsString }}</span>
      ({{selectedFunds.length}})
    </button>
    <div v-if='showFundsFilters'
      class='absolute left-0 border rounded-lg shadow-lg z-20 bg-white'
      style='top: 2.5rem;'>
      <div class='w-full h-full relative pt-8 pb-4 pl-4 pr-4'>
        <button
          @click='closeFundsFilters'
          class='absolute h-5'
          style='top: 0.5rem; right: 0.5rem;'>
        <x-icon class='inline-block text-gray-500 hover:text-gray-900 cursor-pointer h-4' />
        </button>
        <button
          @click='selectAI'
          class='pl-2 pr-12 py-1 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2 whitespace-no-wrap'>
          All AI Funds
        </button>
        <button
          v-if='selectedAll'
          @click='unselectAll'
          class='pl-2 pr-12 py-1 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2 whitespace-no-wrap'>
          Unselect All
        </button>
        <button
          v-if='!selectedAll'
          @click='selectAll'
          class='pl-2 pr-12 py-1 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2 whitespace-no-wrap'>
          Select All
        </button>
        <label v-for='(item, index) in selectableFunds'
          @click='isAI=false'
          :key='`select-item-dropdown-${index}-${item}`'
          class='pl-2 pr-12 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2 whitespace-no-wrap'>
          <input
            type='checkbox'
            v-model='selectedFunds'
            :value='item' />
          <span class='whitespace-no-wrap'>{{ item }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon }      from '@vue-hero-icons/outline'
import cloneDeep      from 'lodash/cloneDeep'

export default {
  name: 'PortfolioFundSelect',
  props: ['selectableFunds'],
  components: {
    XIcon,
  },
  data () {
    return {
      savedFilter: [],
      showFundsFilters: false,
      selectedFunds: [],
      isAI: false,
    }
  },
  watch: {
    'selectableFunds': {
      handler: function () {
        if (this.selectableFunds.length > 0) {
          this.selectAI ()
          this.$emit('done-funds-select', this.selectedFunds)
        }
      },
      immediate: true,
    },
  },
  computed: {
    filterFundsString () {
      if (this.selectableFunds.length === this.selectedFunds.length) {
        return 'All Funds'
      } else if (this.selectedFunds.length === 0) {
        return 'Fund 선택'
      } else if (this.isAI) {
        return 'All AI Funds'
      } else {
        return this.selectedFunds.toString()
      }
    },
    selectedAll () {
      return this.selectedFunds.length === this.selectableFunds.length
    }
  },
  methods: {
    toggleFundsFilters () {
      this.showFundsFilters = !this.showFundsFilters
    },
    closeFundsFilters () {
      this.showFundsFilters = false
      this.doneFundsSelect()
    },
    selectAll () {
      this.selectedFunds = cloneDeep(this.selectableFunds)
      this.isAI = false
    },
    unselectAll () {
      this.selectedFunds = []
      this.isAI = false
    },
    selectAI () {
      this.selectedFunds = cloneDeep(this.selectableFunds.filter((item => item !== 'TFIM' && item.substr(0,2) !== 'TP')))
      this.isAI = true
    },
    doneFundsSelect () {
      this.$emit('done-funds-select', this.selectedFunds)
    }
  }
}
</script>
